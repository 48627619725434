import { NotFoundSection } from "@/components/ErrorPages";
import Head from "next/head";
import { NextPageWithLayout } from "types/nextjs";

const Custom404: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>404: Page not found</title>
      </Head>
      <NotFoundSection />
    </>
  );
};

Custom404.layout = {
  transparentHeader: false,
};

export default Custom404;
