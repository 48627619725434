import Button from "./Button";
import {
  IconActionBookmark,
  IconActionClock,
  IconActionEnvelope,
} from "./Icons";
import { Section, SectionContainer } from "./Sections";
import { ContactUsButton } from "@/components/ContactUsButton";

export const ErrorSection = () => {
  return (
    <Section isFirstSection>
      <SectionContainer grid gridNoYGap>
        <h2 className="col-span-full mb-md text-style-headline2">
          Something went wrong
        </h2>
        <p className="col-span-full mb-lg text-style-headline4">
          We are working on fixing the problem.
        </p>
        <p className="col-span-full text-style-headline4">
          In the meantime, here is what you can do:
          <span className="flex items-center mt-xs">
            <IconActionBookmark className="mr-xs" /> Refresh the page
          </span>
          <span className="flex items-center mt-xs">
            <IconActionClock className="mr-xs" /> Try again in 30 minutes
          </span>
          <span className="flex items-center mt-xs">
            <IconActionEnvelope className="mr-xs" />
            <ContactUsButton
              text="Contact us"
              className="text-style-headline4 mr-xs"
            />
            <span>and tell us what happened</span>
          </span>
        </p>
      </SectionContainer>
    </Section>
  );
};

export const NotFoundSection = () => {
  return (
    <Section isFirstSection>
      <SectionContainer grid gridNoYGap>
        <h2 className="col-span-full text-style-headline2 mb-md">
          Page not found
        </h2>
        <p className="col-span-full tablet-lg:col-span-6 text-style-headline4 mb-md">
          Unfortunately, the page you were looking for could not be found. The
          URL is either incorrect or does not exist.
        </p>
        <span className="col-span-full tablet-lg:col-span-6" />
        <p className="col-span-full tablet-lg:col-span-6 text-style-headline4 mb-lg">
          Try using another URL or go back to the homepage.
        </p>
        <div className="col-span-full">
          <Button kind="primary" label="Go to homepage" href="/" />
        </div>
      </SectionContainer>
    </Section>
  );
};

export const InvalidAccountSection = (props: { userName?: string }) => {
  return (
    <Section isFirstSection>
      <SectionContainer className="space-y-md">
        <h2 className="col-span-full text-style-headline2">Invalid account</h2>
        <p className="text-style-headline4">
          Your account {props.userName ? ` (${props.userName}) ` : ""} is not
          configured to be used at this page.
        </p>
        <p className="text-style-headline4">
          Please{" "}
          <ContactUsButton text="contact us" className="text-style-headline4" />{" "}
          if you expect to be able to use your account here.
        </p>
        <div className="pt-sm">
          <Button plainHtmlATag href="/signout">
            Sign out
          </Button>
        </div>
      </SectionContainer>
    </Section>
  );
};
